import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Typy rozšíření a areálů druhů" />
    <h1>Typy rozšíření a areálů druhů</h1>
    <p>Studium typů roz&scaron;&iacute;řen&iacute; druhu a popis typů are&aacute;lů je dal&scaron;&iacute; ned&iacute;lnou souč&aacute;st&iacute; <Link to="/biogeografie/areografie/">areografie</Link>, potažmo <Link to="/biogeografie/">biogeografie</Link>.</p>
<hr />
<h2>Typy roz&scaron;&iacute;řen&iacute; druhů</h2>
<p>Mezi z&aacute;kladn&iacute; typy či způsoby roz&scaron;&iacute;řen&iacute; druhů, kter&eacute; vyjadřuj&iacute; jak&eacute;si nadřazen&eacute; členěn&iacute; are&aacute;lů, patř&iacute;:</p>
<ul>
<li>kosmopolitn&iacute; roz&scaron;&iacute;řen&iacute;</li>
<li>endemick&eacute; roz&scaron;&iacute;řen&iacute;</li>
<li>zon&aacute;ln&iacute; roz&scaron;&iacute;řen&iacute;</li>
</ul>
<h3>Kosmopolitn&iacute; roz&scaron;&iacute;řen&iacute;</h3>
<p>Kosmopolitn&iacute; roz&scaron;&iacute;řen&iacute; odpov&iacute;d&aacute; velmi velk&eacute;mu are&aacute;lu, kter&yacute; zauj&iacute;m&aacute; vět&scaron;inu kontinentů. Are&aacute;l v&scaron;ak nemus&iacute; zahrnovat celou plochu kontinentů, pouze vhodn&eacute; lokality. Rozli&scaron;ujeme dva z&aacute;kladn&iacute; druhy kosmopolitn&iacute;ch are&aacute;lů:</p>
<ul>
<li>prim&aacute;rně kosmopolitn&iacute; are&aacute;l</li>
<li>sekund&aacute;rně kosmopolitn&iacute; are&aacute;l</li>
</ul>
<h4>Prim&aacute;rně kosmopolitn&iacute; are&aacute;l</h4>
<p>Prim&aacute;rně kosmopolitn&iacute; are&aacute;l, je takov&yacute;, kter&yacute; m&aacute; přirozen&eacute; př&iacute;činy. Mezi rostliny s prim&aacute;rn&iacute;m kosmopolitn&iacute;m roz&scaron;&iacute;řen&iacute;m patř&iacute; hasivka orlič&iacute; (<em>Pteridium aquilinium</em>), r&aacute;kos obecn&yacute; (<em>Phragmites australis</em>) či z živočichů např&iacute;klad sokol stěhovav&yacute; (<em>Falco peregrinus</em>).</p>
<h4>Sekund&aacute;rně kosmopolitn&iacute; are&aacute;l</h4>
<p>Sekund&aacute;rně kosmopolitn&iacute; are&aacute;l je takov&yacute;, kter&yacute; vznikl d&iacute;ky činnosti člověka. Nejzn&aacute;měj&scaron;&iacute; je zřejmě sekund&aacute;rn&iacute; kosmopolitn&iacute; roz&scaron;&iacute;řen&iacute; my&scaron;i dom&aacute;c&iacute; (<em>Mus musculus</em>), z rostlinn&eacute; ř&iacute;&scaron;e pak jitrocelu vět&scaron;&iacute;ho (<em>Plantago major</em>) či koko&scaron;ky pastu&scaron;&iacute; tobolky (<em>Capsella bursa-pastoris</em>).</p>
<h3 id="endemity">Endemick&eacute; roz&scaron;&iacute;řen&iacute;</h3>
<p>Endemick&eacute; roz&scaron;&iacute;řen&iacute; naopak odpov&iacute;d&aacute; omezen&eacute;mu are&aacute;lu. Endemity, rostliny definovan&eacute; t&iacute;mto typem roz&scaron;&iacute;řen&iacute;, jsou totiž v&aacute;z&aacute;ny jen k určit&eacute; oblasti, mimo kterou se nikde jinde přirozeně nevyskytuj&iacute;.</p>
<p>Endemity jsou definov&aacute;ny vždy s ohledem na dan&eacute; &uacute;zem&iacute; a na různou taxonomickou pozici, v z&aacute;kladu se děl&iacute; do těchto dvou skupin:</p>
<ul>
<li><strong>paleoendemit</strong> (taxony reliktn&iacute;ho charakteru, př. smrk omorika (<em>Picea omorika</em>) - třetihorn&iacute; relikt a balk&aacute;nsk&yacute; endemit)</li>
<li><strong>neoendemit</strong> (nově vznikl&eacute; taxony, kter&eacute; se neroz&scaron;&iacute;řily z m&iacute;sta sv&eacute;ho vzniku, př. zvonek jesenick&yacute; (<em>Campanula gelida</em>) - jesenick&yacute; neoendemit)</li>
</ul>
<p>Mezi největ&scaron;&iacute; světov&eacute; hotspoty endemismu rostlin patř&iacute; např&iacute;klad severoz&aacute;padn&iacute; c&iacute;p Jižn&iacute; Ameriky a Indon&eacute;sie. Centry endemismu v Evropě jsou zejm&eacute;na hory jižn&iacute; Evropy a středomořsk&eacute; ostrovy.</p>
<p>Př&iacute;kladem česk&yacute;ch endemick&yacute;ch rostlin je jeř&aacute;b sudetsk&yacute; (<em>Sorbus sudetica</em>) a česk&yacute; (<em>Sorbus bohemica</em>) nebo hvozd&iacute;k moravsk&yacute; (<em>Dianthus moravicus</em>).</p>
<h3>Zon&aacute;ln&iacute; roz&scaron;&iacute;řen&iacute;</h3>
<p>Zon&aacute;ln&iacute; roz&scaron;&iacute;řen&iacute; druhů odpov&iacute;d&aacute; jednotliv&yacute;m klimatick&yacute;m, a t&iacute;m i vegetačn&iacute;m, p&aacute;smům. Takov&eacute; roz&scaron;&iacute;řen&iacute; je důsledkem &uacute;zk&eacute;ho vztahu ke klimatick&yacute;m podm&iacute;nk&aacute;m a specifick&eacute;mu biotopu. Vět&scaron;ina z n&aacute;sleduj&iacute;c&iacute;ch typů are&aacute;lů jsou jen různ&eacute; formy zon&aacute;ln&iacute;ho roz&scaron;&iacute;řen&iacute; organismů.</p>
<hr />
<h2>Typy are&aacute;lů druhů</h2>
<p>Rozdělen&iacute; na typy are&aacute;lů druhů se odv&iacute;j&iacute; předev&scaron;&iacute;m od klimatick&yacute;ch podm&iacute;nek vegetačn&iacute;ch p&aacute;sů. V biogeografii a areografii obvykle rozli&scaron;ujeme:</p>
<ul>
<li><strong>cirkumpol&aacute;rn&iacute; are&aacute;l</strong></li>
<li><strong>bore&aacute;ln&iacute; are&aacute;l</strong></li>
<li><strong>temper&aacute;tn&iacute; are&aacute;l</strong></li>
<li><strong>pantropick&yacute; are&aacute;l</strong></li>
<li><strong>disjunktn&iacute; are&aacute;l</strong></li>
</ul>
<h3>Cirkumpol&aacute;rn&iacute; are&aacute;l</h3>
<p>Cirkumpol&aacute;rn&iacute; are&aacute;l maj&iacute; taxony roz&scaron;&iacute;řen&eacute; v chladněj&scaron;&iacute;m klimatu vysok&yacute;ch zeměpisn&yacute;ch &scaron;&iacute;řek, charakteristick&yacute;m biomem je tundra.</p>
<p>Z rostlin se zde nach&aacute;z&iacute; např&iacute;klad lomik&aacute;men vstř&iacute;cnolist&yacute; (<em>Saxifraga oppositifolia</em>), z živočichů pak medvěd ledn&iacute; (<em>Ursus maritimus</em>) či li&scaron;ka pol&aacute;rn&iacute; (<em>Alopex lagopus</em>).</p>
<h3>Bore&aacute;ln&iacute; are&aacute;l</h3>
<p>Bore&aacute;ln&iacute; are&aacute;l zahrnuje chladn&eacute; oblasti vysok&yacute;ch a středn&iacute;ch zeměpisn&yacute;ch &scaron;&iacute;řek, charakteristick&yacute;m biomem je tajga.</p>
<p>Z rostlin v tomto are&aacute;lu nalezneme např&iacute;klad zimozel severn&iacute; (<em>Linnaea borealis</em>) nebo ostružin&iacute;k moru&scaron;ku (<em>Rubus chamaemorus</em>) a ze zv&iacute;řat pak rosom&aacute;ka sibiřsk&eacute;ho (<em>Gulo gulo</em>) či losa evropsk&eacute;ho (<em>Alces alces</em>).</p>
<h3>Temper&aacute;tn&iacute; are&aacute;l</h3>
<p>V temper&aacute;tn&iacute;m are&aacute;lu se nach&aacute;z&iacute; taxony roz&scaron;&iacute;řen&eacute; ve středn&iacute;ch zeměpisn&yacute;ch &scaron;&iacute;řk&aacute;ch, jde o tzv. temper&aacute;tn&iacute; z&oacute;nu.</p>
<p>Nalezneme zde javory (<em>Acer</em>), duby (<em>Quercus</em>), kachny divok&eacute; (<em>Anas platyrhynchos</em>), krkavec velk&yacute; (<em>Corvus corax</em>) a mnoho dal&scaron;&iacute;ch druhů, kter&eacute; jsou n&aacute;m dobře zn&aacute;my.</p>
<h3>Pantropick&yacute; are&aacute;l</h3>
<p>Pantropick&yacute; are&aacute;l je domovem taxonů roz&scaron;&iacute;řen&yacute;ch pod&eacute;l rovn&iacute;ku, tedy v tropick&yacute;ch oblastech.</p>
<p>Typick&yacute;mi rostlinami jsou palmy (<em>Arecaceae</em>), li&aacute;novec (<em>Gnetum</em>) či ban&aacute;novn&iacute;kovit&eacute; (<em>Musaceae</em>), živočichy pak prim&aacute;ti (<em>Primates</em>) nebo třeba ibis hněd&yacute; (<em>Plegadis falcinellus</em>).</p>
<h3>Disjunktn&iacute; are&aacute;l</h3>
<p>Disjunktn&iacute; are&aacute;l je takov&yacute;, kter&yacute; je rozdělen do dvou a v&iacute;ce izolovan&yacute;ch oblast&iacute; a jejich populace stejn&eacute;ho druhu tak spolu nekomunikuj&iacute;. Roz&scaron;&iacute;řen&iacute; v disjunktn&iacute;m are&aacute;lu může b&yacute;t v z&aacute;kladu dvoj&iacute;ho charakteru:</p>
<ul>
<li><strong>amfiregion&aacute;ln&iacute; roz&scaron;&iacute;řen&iacute;</strong> (are&aacute;l se rozpad&aacute; do dvou subare&aacute;lů oddělen&yacute;ch geografickou přek&aacute;žkou)</li>
<li><strong>polydisjunktn&iacute; roz&scaron;&iacute;řen&iacute;</strong> (are&aacute;l se rozpad&aacute; do v&iacute;ce nesouvisej&iacute;c&iacute;ch jednotek, typicky u vysokohorsk&yacute;ch druhů)</li>
</ul>
<p>Disjunkce lze dělit podle typu geneze na:</p>
<ul>
<li><strong>dispersn&iacute; disjunkce</strong> (hlavně u rostlin s dobrou schopnost&iacute; <Link to="/biogeografie/disperze/">disperze</Link>, typicky rostliny rozn&aacute;&scaron;en&eacute; pt&aacute;ky)</li>
<li><strong>klimatick&eacute; disjunkce</strong> (pokud klimatick&aacute; změna způsob&iacute; neobyvatelnost č&aacute;sti původn&iacute;ho are&aacute;lu, typick&eacute; pro tzv. glaci&aacute;ln&iacute; relikty a pro druhohorn&iacute; a třetihorn&iacute; druhy - př. magn&oacute;lie či alig&aacute;toři)</li>
<li><strong>geologick&eacute; disjunkce</strong> (rozdělen&iacute; are&aacute;lu geologick&yacute;m procesem, typick&eacute; pro taxony jižn&iacute; polokoule, kter&eacute; byly rozdělen&eacute; rozpadem Gondwany)</li>
<li><strong>evolučn&iacute; disjunkce</strong> (v různ&yacute;ch č&aacute;stech are&aacute;lu se vyvinou parapatrickou či sympatrickou <Link to="/biogeografie/evolucni/speciace/">speciac&iacute;</Link> nov&eacute; druhy a jejich předek n&aacute;sledně vyhyne, č&iacute;mž dojde k oddělen&iacute; are&aacute;lů)</li>
</ul>
<p>Disjunkce často vede ke <Link to="/biogeografie/evolucni/speciace/">speciaci</Link>, u evolučn&iacute; disjunkce je dokonce podm&iacute;nkou.</p>
<h4>Hlavn&iacute; typy disjunkc&iacute; evropsk&yacute;ch druhů</h4>
<p>Mezi hlavn&iacute; typy disjunkc&iacute; evropsk&yacute;ch druhů patř&iacute;:</p>
<ul>
<li><strong>arkto-alp&iacute;nsk&aacute; disjunkce</strong> (are&aacute;l v alp&iacute;nsk&eacute;m stupni pohoř&iacute; Evropy a v Arktidě, vznikla v pleistoc&eacute;nu, kdy byly seversk&eacute; druhy vytlačeny na jih)</li>
<li><strong>boreo-mont&aacute;nn&iacute; disjunkce</strong> (are&aacute;l v horsk&eacute; tajze evropsk&yacute;ch pohoř&iacute; a bore&aacute;ln&iacute; oblasti, vznikla v pleistoc&eacute;nu obdobně jako arkto-alp&iacute;nsk&aacute; disjunkce)</li>
<li><strong>evropsko-altajsk&aacute; disjunkce</strong> (pozůstatky souvisl&yacute;ch are&aacute;lů temper&aacute;tn&iacute; z&oacute;ny, kter&eacute; vznikly v pleistoc&eacute;nu)</li>
<li><strong>evropsko-v&yacute;chodoasijsk&aacute; disjunkce</strong> (vznikla rozpadem souvisl&yacute;ch are&aacute;lů vlivem klimatick&yacute;ch změn na konci třetihor a zač&aacute;tku pleistoc&eacute;nu</li>
<li><strong>středoevropsko-kavkazsk&aacute; disjunkce</strong> (předev&scaron;&iacute;m u horsk&yacute;ch druhů, jejichž are&aacute;l zasahoval v pleistoc&eacute;nu až na Kavkaz či d&aacute;le)</li>
<li><strong>mediter&aacute;nn&iacute; disjunkce</strong> (nesouvisl&eacute; are&aacute;ly ve Středomoř&iacute;, pravěpodobně způsobeny změnami hladiny Středozemn&iacute;ho moře a změnami klimatu na konci třetihor a v pleistoc&eacute;nu)</li>
</ul>
<hr />
<h2>Biogeografick&eacute; relikty</h2>
<p>Biogeografick&eacute; relikty jsou taxony, kter&eacute; měly kdysi hojn&eacute; roz&scaron;&iacute;řen&iacute;, ale v současnosti se vyskytuj&iacute; pouze na omezen&eacute;m &uacute;zem&iacute;. Toto omezen&eacute; &uacute;zem&iacute; se označuje pojmem refugium. Rozli&scaron;ujeme dva z&aacute;kladn&iacute; druhy biogeografick&yacute;ch reliktů:</p>
<ul>
<li><strong>evolučn&iacute; relikty</strong></li>
<li><strong>klimatick&eacute; relikty</strong></li>
</ul>
<h3>Evolučn&iacute; relikty</h3>
<p>Evolučn&iacute; relikty jsou taxony, kter&eacute; jsou pozůstatkem dř&iacute;ve roz&scaron;&iacute;řen&yacute;ch evolučn&iacute;ch lini&iacute;. Př&iacute;klady z rostlinn&eacute; ř&iacute;&scaron;e mohou b&yacute;t jinan dvoulaločn&yacute; (<em>Ginkgo biloba</em>) či liliovn&iacute;k (<em>Liriodendron</em>), kter&eacute; jsou terciern&iacute;mi relikty. Živočich, kter&yacute; se řad&iacute; do evolučn&iacute;ch reliktů, je pak např&iacute;klad latim&eacute;rie podivn&aacute; (<em>Latimeria chalumnae</em>).</p>
<h3>Klimatick&eacute; relikty</h3>
<p>Klimatick&yacute;m reliktem je takov&yacute; taxon, jehož are&aacute;l byl v&yacute;razně zmen&scaron;en v důsledku klimatick&yacute;ch změn, vět&scaron;inou v průběhu pleistoc&eacute;nu (v takov&eacute;m př&iacute;padě mluv&iacute;me vět&scaron;inou o glaci&aacute;ln&iacute;ch reliktech).</p>
<p>Typick&yacute;m př&iacute;kladem živočicha, kter&yacute; je klimatick&yacute;m (glaci&aacute;ln&iacute;m) reliktem je svi&scaron;ť (<em>Marmota marmota</em>) vyskytuj&iacute;c&iacute; se v Tatr&aacute;ch. Z česk&yacute;ch glaci&aacute;ln&iacute;ch reliktů lze zm&iacute;nit borovici kleč (<em>Pinus mugo</em>), podbělici alpskou (<em>Homogyne alpina</em>) či bř&iacute;zu trpaslič&iacute; (<em>Betula nana</em>).</p>
    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Typy rozšíření a areálů druhů</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/areografie/faktory-ovlivnujici-rozsireni-druhu/"><button className="inv">&larr; Faktory ovlivňující rozšíření druhu</button></Link>
    <Link to="/biogeografie/spolecenstva/"><button className="inv">Společenstva &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
